<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formPis" lazy-validation>
      <v-container fluid>
        <v-widget title="Novo Item na Tabela PIS">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="Código" v-model="pis.codigo" data-cy="codigo"></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Descrição" v-model="pis.descricao" :rules="validarDescricao" required data-cy="descricao"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid" data-cy="salvar">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';

export default {

  data: () => ({
    valid: false,
    validarDescricao: [v => !!v || "Descrição Obrigatória"],
    constantes: constantes
  }),

  computed: {

    ...mapState('pis', {
      pis: 'pisAtual',
      carregando: 'carregando'
    }),
    
    ...mapGetters('pis', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {
    
    async salvar() {
      if (this.$refs.formPis.validate()) {
        try{
          await this.$store.dispatch('pis/salvar');
          this.$router.push({ name: 'pis'});
        }
        catch(erro){
          console.log('Erro ao salvar');
        }
      }
    },

    cancelar() {
      this.$store.commit('pis/estadoInicial');
      this.$router.push({name: 'pis'});
    }
  },

  async created(){
    
    try{

      this.loading = true;
      
      let pis = this.$store.state.pis.pisAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!pis._id || pis._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um banco para editar');
        this.$router.push({ name: 'pis' });
      }
      
      if(!estaEditando){
        this.$store.commit('pis/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>